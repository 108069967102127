<template>
  <div style="margin-top: 100px">
    <BasePageBreadCrumb
      :pages="pages"
      :title="total_clients + ' clientes | ' + total_uau + ' uau'"
      :menu="menu"
    />
    <div class="container-fluid qsuite-c-fluid-0">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-md-5 col-lg-6">
                <div class="form-group d-flex">
                  <input
                    type="search"
                    class="form-control campo-procurar"
                    placeholder="Procurar"
                    v-model="search"
                  />
                  <button
                    type="submit"
                    class="btn btn-info"
                    @click="fetchClients"
                  >
                    Buscar
                  </button>
                </div>
              </div>
              <div
                class="d-flex justify-content-end col-12 col-md-6 col-lg-3 d-none d-lg-block"
              >
                <div class="form-group d-flex">
                  <button
                    :disabled="disabledButtonSyncronized"
                    type="button"
                    @click="sincronizedUAU"
                    class="btn btn-warning"
                  >
                    <i class="fas fa-sync mr-2"></i>Sincronizador UAU
                  </button>
                </div>
              </div>
              <div
                class="d-flex justify-content-end col-12 col-md-6 col-lg-3 d-none d-lg-block"
              >
                <div class="form-group d-flex">
                  <button
                    @click="listUAUClientsNotImported()"
                    type="button"
                    class="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <i class="fas fa-download mr-2"></i>Importar cliente UAU
                  </button>
                </div>
              </div>
              <!-- <div v-for="integration in integrations" class="d-flex justify-content-end col-12 col-md-6 col-lg-3 d-none d-lg-block">
                            <div class="form-group d-flex" v-if="integration.name =='UAU'">                             
                                <button :disabled="disabledButtonSyncronized" type="button" @click="sincronizedUAU" class="btn btn-warning"><i class="fas fa-sync mr-2"></i>Sincronizador UAU</button>
                            </div>
                        </div> -->

              <!-- Modal -->
              <div
                class="modal fade"
                id="exampleModal"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-scrollable">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Escolha um cliente para importar
                      </h5>
                    </div>
                    <div class="modal-body">
                      <label>Buscar clientes</label>
                      <input
                        type="text"
                        class="ml-2 fom-control"
                        @keyup="buscaClientes($event)"
                      />
                      <div v-if="uau_clients_not_imported.length >= 1">
                        <div v-if="uau_clients_not_imported_filtro.length >= 1">
                          <div
                            v-for="(
                              uau_client, id
                            ) in uau_clients_not_imported_filtro"
                          >
                            <div class="d-flex justify-content-between mt-3">
                              <p>{{ uau_client.Nome_pes }}</p>
                              <button
                                type="button"
                                @click="importClient(id)"
                                class="btn btn-primary"
                                data-bs-dismiss="modal"
                              >
                                Importar
                              </button>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <div
                            v-for="(uau_client, id) in uau_clients_not_imported"
                          >
                            <div class="d-flex justify-content-between mt-3 border-b">
                              <p>{{ uau_client.Nome_pes }}</p>
                              <button
                                type="button"
                                @click="importClient(id)"
                                class="btn btn-primary"
                                data-bs-dismiss="modal"
                              >
                                Importar
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <p>Não tem clientes UAU para serem importados</p>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Fechar
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-3 d-none d-lg-block">
                <div class="form-group d-flex">
                  <b-link :to="{ name: 'ClientNew' }" class="ml-auto">
                    <button type="submit" class="btn btn-dark">
                      <i class="fas fa-street-view mr-2"></i>Novo cliente
                    </button>
                  </b-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card-list-legenda">
              <div class="card-body-list-legend d-none d-lg-block">
                <div class="col-12 p-0 align-self-center">
                  <div class="col-12">
                    <div class="row">
                      <div
                        class="col-12 col-md-12 col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status"
                      >
                        <p class="text-truncate title-legend-list">
                          <i
                            class="fa fa-circle color-ico-legenda font-12"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Status"
                          ></i>
                        </p>
                      </div>
                      <div
                        class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img"
                      >
                        <p
                          class="text-truncate title-legend-list"
                          style="width: 45px"
                        >
                          &nbsp;
                        </p>
                      </div>
                      <div
                        class="col-7 col-md-8 col-lg-8 p-0 order-3 align-self-center"
                      >
                        <div class="col-12">
                          <div class="row">
                            <div
                              class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list"
                            >
                              <p class="text-truncate title-legend-list">
                                Nome
                              </p>
                            </div>
                            <div
                              class="col-12 col-md-12 col-lg-2 align-self-center text-muted pad-content-list"
                            >
                              <p class="text-truncate title-legend-list">
                                E-mail
                              </p>
                            </div>
                            <div
                              class="col-12 col-md-12 col-lg-2 align-self-center pad-content-list"
                            >
                              <p class="text-truncate title-legend-list">
                                Whatsapp
                              </p>
                            </div>
                            <div
                              class="col-12 col-md-12 col-lg-2 align-self-center pad-content-list"
                            >
                              <p class="text-truncate title-legend-list">
                                Origem
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta"
                      >
                        &nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-list" v-for="item in clients" :key="item.id">
              <div class="card-body-list">
                <div class="col-12 p-0 align-self-center">
                  <div class="col-12">
                    <div class="row">
                      <div
                        class="col-12 col-md-auto col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status d-none d-lg-block"
                      >
                        <i
                          class="fa fa-circle text-success font-12"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Status"
                        ></i>
                      </div>
                      <div
                        class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img"
                      >
                        <img
                          v-if="item.image"
                          :src="item.image"
                          alt="user"
                          class="rounded-circle card-body-list-img"
                        />
                      </div>
                      <div
                        class="col-7 col-md-6 col-lg-8 p-0 order-3 align-self-center"
                      >
                        <div class="col-12">
                          <div class="row">
                            <div
                              class="col-12 col-md-12 col-lg-3 align-self-center pad-content-list"
                            >
                              <h6 class="text-truncate card-body-list-title">
                                {{ item.first_name }} {{ item.last_name }}
                              </h6>
                              <h6 class="text-truncate cpf">{{ item.cpf }}</h6>
                            </div>
                            <div
                              class="col-12 col-md-12 col-lg-4 align-self-center text-muted pad-content-list"
                            >
                              <h6 class="text-truncate e-mail">
                                {{ item.email }}
                              </h6>
                            </div>
                            <div
                              class="col-12 col-md-12 col-lg-2 align-self-center pad-content-list"
                            >
                              <h6 class="text-truncate whats">
                                {{ item.cellphone }}
                              </h6>
                            </div>
                            <div
                              class="col-12 col-md-12 col-lg-2 align-self-center pad-content-list"
                            >
                              <h6 class="text-truncate whats">
                                {{ item.origin_uau ? "UAU" : "" }}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta"
                      >
                        <b-link
                          :to="{ name: 'ClientEdit', params: { id: item.id } }"
                          class="btn btn-dark rounded-circle btn-circle font-20 card-body-list-cta"
                          >+</b-link
                        >
                        <a
                          class="btn btn-success ml-1 text-white rounded-circle btn-circle font-20 card-body-list-cta"
                          target="_blank"
                          :href="`https://api.whatsapp.com/send?phone=${
                            item.cellphone
                              ? item.cellphone.replace(/\D/g, '')
                              : ''
                          }`"
                          ><i class="fab fa-whatsapp font-20"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 text-center p-4" v-if="clients.length == 0">
              Nenhum cliente encontrado.
            </div>
          </div>
        </div>
        <div class="col-12">
          <BasePagination
            v-if="clients.length > 0"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            @to-page="toPage"
            @per-page="perPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClientService from "@/services/resources/ClientService";
import SyncUsersService from "@/services/resources/Uau/SyncUsers";
import ImportNotImportedClientService from "@/services/resources/Uau/ImportNotImportedClient";

const service = ClientService.build();
const serviceSyncUsersUau = SyncUsersService.build();
let serviceImportNotImportedClient = ImportNotImportedClientService.build();

export default {
  data() {
    return {
      disabledButtonSyncronized: false,
      clients: [],
      total_clients: 0,
      total_uau: 0,
      uau_clients_not_imported: [],
      uau_clients_not_imported_filtro: [],
      integrations: [],
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
        itens: 0,
      },
      search: null,
      pages: [
        {
          name: "dashboard",
          to: "Dashboard",
        },
        {
          name: "clientes",
          to: "Client",
        },
      ],
      menu: [
        {
          name: "Novo cliente",
          to: "ClientNew",
        },
        {
          name: "Clientes",
          to: "Client",
        },
      ],
    };
  },
  methods: {
    sincronizedUAU() {
      this.disabledButtonSyncronized = true;
      this.$bvToast.toast(
        "Processo de importação de dados em andamento, por favor aguarde.",
        {
          title: "Atenção",
          autoHideDelay: 10000,
          type: "success",
        }
      );

      serviceSyncUsersUau.search("").then(() => {
        this.disabledButtonSyncronized = false;
      });
    },
    buscaClientes(eve) {
      let nomes = [];
      let todos_nomes = this.uau_clients_not_imported;
      todos_nomes.forEach((ele) => {
        if (
          ele.Nome_pes.toLowerCase().indexOf(
            eve.srcElement.value.toLowerCase()
          ) > -1
        ) {
          nomes[nomes.length] = ele;
        }
      });

      this.uau_clients_not_imported_filtro = nomes;
      if (this.uau_clients_not_imported_filtro.length < 1) {
        this.uau_clients_not_imported_filtro = [];
      }
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchClients();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchClients();
    },
    listUAUClientsNotImported() {
      serviceImportNotImportedClient.search().then(
        function (re) {
          this.uau_clients_not_imported = re;
        }.bind(this)
      );
    },
    importClient(i) {
      let information = {
        client_uau: this.uau_clients_not_imported[i]["Cod_pes"],
      };
      serviceImportNotImportedClient.create(information).then(
        function (re) {
          console.log(re);
        }.bind(this)
      );
    },
    fetchClients() {
      let data = {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
      };

      if (this.search) {
        data["search"] = this.search;
      }

      service
        .search(data)
        .then((resp) => {
          this.total_clients = resp[0].total;
          this.total_uau = resp.total_uau;
          this.clients = resp[0].data.map((item) => {
            return {
              ...item,
              cellphone: item.cellphone ? item.cellphone : "NÃO CADASTRADO ",
              cpf: item.cpf ? item.cpf : item.cnpj,
              first_name: item.first_name ? item.first_name : item.social_name,
            };
          });
          this.pagination.totalPages = resp[0].last_page;
          this.pagination.itens = resp[0].total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.fetchClients();
    this.integrations = this.$store.getters.getProfile.company.integrations;
    console.log(this.$store.getters.getProfile);
  },
};
</script>
